import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Ultimi 5 minuti' },
  { from: 'now-15m', to: 'now', display: 'Ultimi 15 minuti' },
  { from: 'now-30m', to: 'now', display: 'Ultimi 30 minuti' },
  { from: 'now-1h', to: 'now', display: 'Ultima ora' },
  { from: 'now-3h', to: 'now', display: 'Ultime 3 ore' },
  { from: 'now-6h', to: 'now', display: 'Ultime 6 ore' },
  { from: 'now-12h', to: 'now', display: 'Ultime 12 ore' },
  { from: 'now-24h', to: 'now', display: 'Ultime 24 ore' },
  { from: 'now-2d', to: 'now', display: 'Ultimi 2 giorni' },
  { from: 'now-7d', to: 'now', display: 'Ultimi 7 giorni' },
  { from: 'now-30d', to: 'now', display: 'Ultimi 30 giorni' },
  { from: 'now-90d', to: 'now', display: 'Ultimi 90 giorni' },
  { from: 'now-6M', to: 'now', display: 'Ultimi 6 mesi' },
  { from: 'now-1y', to: 'now', display: 'Ultimo anno' },
  { from: 'now-2y', to: 'now', display: 'Ultimi 2 anni' },
  { from: 'now-5y', to: 'now', display: 'Ultimi 5 anni' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'ieri' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'L\'altro ieri' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Questo giono ma scorsa settimana' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Settimana precedente' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Mese precedente' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Trimestre fiscale precedente' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Anno precedente' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Anno fiscale precedente' },
  { from: 'now/d', to: 'now/d', display: 'Oggi' },
  { from: 'now/d', to: 'now', display: 'Oggi finora' },
  { from: 'now/w', to: 'now/w', display: 'Questa settimana' },
  { from: 'now/w', to: 'now', display: 'Questa settimana finora' },
  { from: 'now/M', to: 'now/M', display: 'Questo mese' },
  { from: 'now/M', to: 'now', display: 'Questo mese finora' },
  { from: 'now/y', to: 'now/y', display: 'Questo anno' },
  { from: 'now/y', to: 'now', display: 'Questo anno finora' },
  { from: 'now/fQ', to: 'now', display: 'Questo trimestre fiscale finora' },
  { from: 'now/fQ', to: 'now/fQ', display: 'Questo trimestre fiscale' },
  { from: 'now/fy', to: 'now', display: 'Questo trimestre fiscale finora' },
  { from: 'now/fy', to: 'now/fy', display: 'Questo anno fiscale' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'Gennaio', value: 0 },
  { label: 'Febbraio', value: 1 },
  { label: 'Marzo', value: 2 },
  { label: 'Aprile', value: 3 },
  { label: 'Maggio', value: 4 },
  { label: 'Giugno', value: 5 },
  { label: 'Luglio', value: 6 },
  { label: 'Agosto', value: 7 },
  { label: 'Settembre', value: 8 },
  { label: 'Ottobre', value: 9 },
  { label: 'Novembre', value: 10 },
  { label: 'Dicembre', value: 11 },
];
